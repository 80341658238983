<template>
  <div class="container">
    <el-card shadow="hover">
      <h1 class="title">注册账号</h1>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px" style="width:100%;">
        <el-form-item label="登录账号：" prop="account">
          <el-input v-model="form.account" name="account" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="电话号码：" prop="tel" >
          <el-input type="text" name="tel" v-model="form.tel" :maxlength="11" ></el-input>
        </el-form-item>
        <el-form-item label="登陆密码：" prop="password">
          <el-input type="password" name="password" v-model="form.password" autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="checkPassword">
          <el-input type="password" name="checkPassword" v-model="form.checkPassword" autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="用户QQ：" prop="qq" >
          <el-input v-model="form.qq" name="qq" :maxlength="20"></el-input>
        </el-form-item>
        <el-row class="spacer">
          <el-button type="primary" @click="submit" class="login-btn" :loading="loading" style="width:100%;">注册</el-button>
        </el-row>
        <el-row class="mt-6">
          <div class="mx-auto">
            已有账号?<el-link type="primary" href="/#/login"><span class="mx-2">登录账号</span></el-link>
          </div>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import * as Api from "@/api";
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.checkPassword !== "") {
          this.$refs.form.validateField("checkPassword");
        }
        callback();
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      form: {
        account: "",
        password: "",
        checkPassword: "",
        qq: "",
        tel: "",
      },
      rules: {
        account: [{required: true, message: "请输入账号", trigger: "blur" }],
        password: [
          { validator: validatePass, trigger: "blur" },
        ],
        checkPassword: [
          { validator: validateCheckPass, trigger: "blur" },
        ],
        qq: [{required: true, message: "请输入QQ账号", trigger: "blur" }],
        tel: [{required: true, message: "请输入手机号", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.register();
        }
      });
    },
    register() {
      Api.registerApi(this.form)
        .then(({ data }) => {
          Api.sucessMessage(data.errorMessage);
          this.$router.push("/login");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-card {
  width: 400px;
  border-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.spacer {
  margin-bottom: 20px;
}
.login-btn {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
</style>